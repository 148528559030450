export const DownSmallIcon = () => {
    return (
        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="down-small-icon">
            <path
                d="M3.87119 6.68182C4.15066 7.10606 4.84934 7.10606 5.12881 6.68182L8.90164 0.954545C9.18111 0.530303 8.83177 0 8.27283 0H0.727165C0.168227 0 -0.181109 0.530303 0.0983599 0.954545L3.87119 6.68182Z"
                fill="#FF3E13"
            />
        </svg>
    );
};
