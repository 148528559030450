export const LessItemsIcon = () => {
    return (
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.77942 0.501775C5.17282 0.0929424 5.82718 0.0929418 6.22058 0.501775L10.3657 4.80955C10.9771 5.44486 10.5268 6.50293 9.64516 6.50293L1.35484 6.50293C0.473176 6.50293 0.0229389 5.44486 0.63426 4.80955L4.77942 0.501775Z"
                fill="black"
            />
        </svg>
    );
};
