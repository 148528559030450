import { SelectSelectedOperator } from '../../Selects/SelectSelectedOperator';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userRelatedDataSelector, userRelatedFirstOperatorSelector } from '../../../selectors/accountSelectors';
import { IUserRelatedOperator } from '../../../Services/Api/Account/IAccountApiService';

interface OperatorMainFilterProps {
    isInProgress: boolean;
    onChange: (id: number) => void;
}

export const OperatorMainFilter = ({ isInProgress, onChange }: OperatorMainFilterProps) => {
    const { operators } = useSelector(userRelatedDataSelector);
    const userRelatedOperator = useSelector(userRelatedFirstOperatorSelector);
    const [selectedOperatorId, setSelectedOperatorId] = useState(userRelatedOperator?.id);

    const selectedOperator = operators?.find((o: IUserRelatedOperator) => o.id === selectedOperatorId);
    const selectedOperatorName = selectedOperator ? selectedOperator.name : userRelatedOperator?.name;

    useEffect(() => {
        if (userRelatedOperator) {
            setSelectedOperatorId(userRelatedOperator.id);
            onChange(userRelatedOperator.id)
        }
    }, [userRelatedOperator]);

    return (
        <div className="main-filter">
            <div className="title">{selectedOperatorName}</div>
            <div className="filter">
                Select Operator
                <div className="filter-select">
                    {selectedOperatorId && (
                        <SelectSelectedOperator
                            defaultValue={{
                                value: selectedOperatorId,
                                label: selectedOperatorName,
                            }}
                            setValue={(id: number) => {
                                setSelectedOperatorId(id);
                                onChange(id);
                            }}
                            isInProgress={isInProgress}
                            isClearable={false}
                            placeholder="* Filter By Operator..."
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
