import { createSlice } from '@reduxjs/toolkit';
import { IGamesState } from '../types';
import { toggleIdentifyByImage } from './thunk';

export const initialState: IGamesState = {
    allGames: {
        data: [],
        pagination: { totalPages: 1 },
    },
    notFoundGames: {
        data: [],
        pagination: { totalPages: 1 },
    },
    isInProgress: {
        fetchAllGames: false,
        fetchNotFoundGames: false,
        matchGames: false,
        createAndMatchGame: false,
        toggleIdentifyByImage: false,
        mergeGames: false,
    },
    message: '',
    success: null,
};

const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setGamesInProgressAction(state, action: { payload: keyof typeof initialState.isInProgress }) {
            state.isInProgress[action.payload] = true;
        },
        fetchAllGamesSuccessAction(state, action) {
            state.isInProgress.fetchAllGames = false;
            state.allGames.pagination = action.payload.pagination;
            state.allGames.data = action.payload.data;
        },
        fetchNotFoundGamesSuccessAction(state, action) {
            state.notFoundGames.data = action.payload.data;
            state.notFoundGames.pagination = action.payload.pagination;
            state.isInProgress.fetchNotFoundGames = false;
        },
        createAndMatchGameSuccessAction(state, action) {
            state.isInProgress.createAndMatchGame = false;
            state.message = action.payload;
            state.success = true;
        },
        matchGamesSuccessAction(state, action) {
            state.isInProgress.matchGames = false;
            state.message = action.payload;
            state.success = true;
        },
        matchGamesFailedAction(state, action) {
            state.isInProgress.matchGames = false;
            state.message = action.payload;
            state.success = false;
        },
        toggleIdentifyByImageSuccessAction(state, action) {
            state.isInProgress.toggleIdentifyByImage = false;
            state.message = action.payload;
            state.success = true;
        },
        mergeGamesSuccessAction(
            state,
            {
                payload: { message, success },
            }: {
                payload: { message: string; success: boolean };
            }
        ) {
            state.isInProgress.mergeGames = false;
            state.message = message;
            state.success = success;
        },
        resetGamesReducerStatus(state) {
            state.message = '';
            state.success = null;
        },
    },
});

export const {
    setGamesInProgressAction,
    fetchAllGamesSuccessAction,
    fetchNotFoundGamesSuccessAction,
    matchGamesSuccessAction,
    matchGamesFailedAction,
    resetGamesReducerStatus,
    createAndMatchGameSuccessAction,
    toggleIdentifyByImageSuccessAction,
    mergeGamesSuccessAction,
} = gamesSlice.actions;

export default gamesSlice.reducer;
