import React, { useEffect, useRef, useState } from 'react';
import { Alert, Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPortfolio } from '../../../slices/reports/thunk';
import { portfolioDataSelector } from '../../../selectors/reportsSelectors';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { Label } from '../../../Components/Common/Label';
import { getComparisonColor } from '../../../utils/GameUtils';
import { ClearFilters } from '../../../Components/Common/ClearFilters';
import { OperatorRowItem } from '../../../Components/Common/Table/OperatorRowItem';
import { MoreItemsIcon } from '../../../Layouts/SVG/InfoCard/MoreItemsIcon';
import { LessItemsIcon } from '../../../Layouts/SVG/InfoCard/LessItemsIcon';

const defaultPopupState = {
    isOpen: false,
    position: {
        top: 0,
        left: 0,
    },
};
export const Portfolio = () => {
    const [popupState, setPopupState] = useState(defaultPopupState);
    const popupRef = useRef(null);

    const handleButtonClick = (e: any, index: number) => {
        setSelectedOperatorIndex(index);
        const rect = e.target.getBoundingClientRect();
        setPopupState({
            isOpen: selectedOperatorIndex !== index || !popupState.isOpen,
            position: {
                top: rect.bottom + window.scrollY - 25,
                left: rect.left + window.scrollX,
            },
        });
    };

    const closePopup = () => {
        setPopupState(defaultPopupState);
    };

    const dispatch: any = useDispatch();
    const { user } = useProfile();

    const { operators, isInProgress } = useSelector(portfolioDataSelector);
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);

    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(-1);
    const [selectedProviderId, setSelectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        dispatch(fetchPortfolio(selectedProviderId, selectedGameId, selectedOperatorId));
    }, [selectedProviderId, selectedGameId, selectedOperatorId, dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Portfolio Overview'} />
                                <Row>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Active Games"
                                            iconType="game"
                                            amount={relatedDataCounts.games}
                                        />
                                    </Col>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Operators"
                                            iconType="operator"
                                            iconColor="warning"
                                            amount={relatedDataCounts.operators}
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            value={selectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedOperator
                                                            value={selectedOperatorId}
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <ClearFilters
                                                            methods={[setSelectedGameId, setSelectedOperatorId]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {!selectedOperatorId && !selectedProviderId && !selectedGameId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select a provider
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : operators.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <td>Operator</td>
                                                                    {!selectedGameId ? (
                                                                        <>
                                                                            <td>Missing Games</td>
                                                                            <td className="min-w">Games</td>
                                                                        </>
                                                                    ) : (
                                                                        <td>State</td>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {operators.map(
                                                                    ({ operator, games, missing }, index) => {
                                                                        return (
                                                                            <tr key={`section-info-${operator.id}`}>
                                                                                <td>
                                                                                    <OperatorRowItem
                                                                                        operator={operator}
                                                                                    />
                                                                                </td>
                                                                                {!selectedGameId ? (
                                                                                    <>
                                                                                        <td>
                                                                                            <div className="game-list-row">
                                                                                                {missing
                                                                                                    .slice(0, 5)
                                                                                                    .map(
                                                                                                        (
                                                                                                            game,
                                                                                                            gameIndex
                                                                                                        ) => {
                                                                                                            return (
                                                                                                                <Label
                                                                                                                    key={`section-info-${operator.id}-game-${game.id}`}
                                                                                                                >
                                                                                                                    {
                                                                                                                        game.name
                                                                                                                    }
                                                                                                                    {gameIndex ===
                                                                                                                        4 &&
                                                                                                                        missing.length >
                                                                                                                            5 && (
                                                                                                                            <span
                                                                                                                                className="more-games-btn"
                                                                                                                                onClick={(
                                                                                                                                    e
                                                                                                                                ) => {
                                                                                                                                    return handleButtonClick(
                                                                                                                                        e,
                                                                                                                                        index
                                                                                                                                    );
                                                                                                                                }}
                                                                                                                                color="primary"
                                                                                                                            >
                                                                                                                                +{' '}
                                                                                                                                {missing.length -
                                                                                                                                    5}
                                                                                                                                <MoreItemsIcon />
                                                                                                                            </span>
                                                                                                                        )}
                                                                                                                </Label>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="text-start">
                                                                                            <span
                                                                                                className={`actual-amount ${getComparisonColor(games.length, relatedDataCounts.games)}`}
                                                                                            >
                                                                                                {relatedDataCounts.games -
                                                                                                    games.length}
                                                                                            </span>
                                                                                            /{relatedDataCounts.games}
                                                                                        </td>
                                                                                    </>
                                                                                ) : (
                                                                                    <td>
                                                                                        {games.length ? (
                                                                                            <Badge color="success">
                                                                                                Exists
                                                                                            </Badge>
                                                                                        ) : (
                                                                                            <Badge color="warning">
                                                                                                Missing
                                                                                            </Badge>
                                                                                        )}
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {popupState.isOpen && (
                <>
                    <div
                        ref={popupRef}
                        className="more-games-popup"
                        style={{
                            position: 'absolute',
                            top: popupState.position.top,
                            left: popupState.position.left,
                        }}
                    >
                        <div className="header">
                            {operators?.[selectedOperatorIndex]?.missing?.length || 0} Missing Games
                            <button className="close-btn" onClick={closePopup}>
                                <LessItemsIcon />
                            </button>
                        </div>
                        <div className="body">
                            <ul>
                                {operators?.[selectedOperatorIndex]?.missing.map((game) => (
                                    <li key={`missing-popup-game-${game.id}-popupState.rowId`}>{game.name}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="more-games-overlay" onClick={closePopup}></div>
                </>
            )}
        </React.Fragment>
    );
};
