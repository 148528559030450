export const ClearFilters = ({ methods }: { methods: ((_: any) => any)[] }) => {
    return (
        <div
            className="clear-filters-btn"
            onClick={() => {
                methods.forEach((method) => method(0));
            }}
        >
            Clear All
        </div>
    );
};
