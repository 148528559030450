export const OperatorIcon = ({ color }: { color?: string }) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`icon ${color}`}
        >
            <circle cx="22" cy="22" r="22" fillOpacity="0.85" />
            <path d="M22 9L10 21V32H34V21L22 9ZM30 28H14V24H30V28Z" fill="white" />
            <path d="M34 9H10V33H34V9Z" fill="white" fillOpacity="0.01" />
        </svg>
    );
};
