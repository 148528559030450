import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';

interface SwitchProps {
    defaultValue: boolean;
    onChange: (value: boolean) => void;
    id: string;
    label: string;
}

export const Switch = ({ defaultValue, onChange, id, label }: SwitchProps) => {
    const [checked, setChecked] = useState(defaultValue);
    return (
        <div className="form-check form-switch form-switch-secondary">
            <Input
                className="form-check-input"
                type="checkbox"
                id={id}
                role="switch"
                checked={checked}
                onChange={(e) => {
                    setChecked(e.target.checked);
                    onChange(e.target.checked);
                }}
            />

            <Label className="form-check-label" for={id}>
                {' '}
                {label}
            </Label>
        </div>
    );
};
