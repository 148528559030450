export const UpIcon = () => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.16159 0.5C5.53422 -0.166667 6.46578 -0.166666 6.83841 0.5L11.8689 9.5C12.2415 10.1667 11.7757 11 11.0304 11H0.969553C0.224302 11 -0.241479 10.1667 0.131147 9.5L5.16159 0.5Z"
                fill="#13BF24"
            />
        </svg>
    )
}
