export const UpSmallIcon = () => {
    return (
        <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="up-small-icon">
            <path
                d="M5.12881 0.318181C4.84934 -0.106061 4.15066 -0.106061 3.87119 0.318182L0.0983595 6.04545C-0.18111 6.4697 0.168228 7 0.727166 7L8.27283 7C8.83177 7 9.18111 6.4697 8.90164 6.04545L5.12881 0.318181Z"
                fill="#13BF24"
            />
        </svg>
    );
};
