import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGamesOnSections } from '../../../slices/reports/thunk';
import { gamesOnSectionsDataSelector } from '../../../selectors/reportsSelectors';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { GameMainFilter } from '../../../Components/Common/Filter/GameMainFilter';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { SectionRowItem } from '../../../Components/Common/Table/SectionRowItem';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';
import { getComparisonColor } from '../../../utils/GameUtils';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';

export const GamesOnSections = () => {
    const dispatch: any = useDispatch();

    const { operators, isInProgress } = useSelector(gamesOnSectionsDataSelector);
    const { operators: operatorsCount } = useSelector(userRelatedDataCountsSelector);

    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);
    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState(0);

    useEffect(() => {
        selectedGameId && dispatch(fetchGamesOnSections(selectedGameId, selectedOperatorId));
    }, [selectedGameId, selectedOperatorId, dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Operator Sections'} />
                                <GameMainFilter isInProgress={isInProgress} onChange={setSelectedGameId} />
                                <Row>
                                    {!selectedOperatorId && !isInProgress && (
                                        <Col xl={3} md={6} sm={12}>
                                            <InfoCard
                                                title="Active Games"
                                                iconType="game"
                                                iconColor="warning"
                                                amount={operators.length}
                                                subAmount={operatorsCount}
                                                amountColor={getComparisonColor(operators.length, operatorsCount)}
                                            />
                                        </Col>
                                    )}
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedOperator
                                                            setValue={setSelectedOperatorId}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operator..."
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {!selectedGameId ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select a game
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : operators.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-with-manu">
                                                        <div className="menu">
                                                            <div className="title">Operators</div>
                                                            <ul>
                                                                {operators.map(({ operator }, index) => {
                                                                    return (
                                                                        <li
                                                                            key={`operator-${operator.id}`}
                                                                            onClick={() => {
                                                                                setSelectedOperatorIndex(index);
                                                                            }}
                                                                            className={
                                                                                index === selectedOperatorIndex
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            {operator.name}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Pages</td>
                                                                        <td className="min-w">Current position</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {operators[selectedOperatorIndex]?.sections.map(
                                                                        (section) => {
                                                                            return (
                                                                                <tr
                                                                                    key={`section-info-${selectedOperatorIndex}-${section.id}`}
                                                                                >
                                                                                    <td>
                                                                                        <SectionRowItem
                                                                                            name={section.name}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <RatioDisplay
                                                                                            current={
                                                                                                section.game.position
                                                                                            }
                                                                                            total={section.game_count}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
