import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllGames, toggleIdentifyByImage } from '../../../slices/games/thunk';
import { allGamesDataSelector } from '../../../selectors/gamesSelectors';
import { Pagination } from 'Components/Common/Pagination';
import { Search } from 'Components/Common/Search';
import { DropDown } from 'Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';
import { IFetchAllGamesApiRequestBody } from '../../../slices/types';
import { ToastContainer } from 'react-toastify';
import { Switch } from '../../../Components/Common/Form/Switch';
import { IReportGame } from '../../../Services/Api/IReportApiService';
import { MergeGamesDialog } from './MergeGamesDialog';

const defaultParams = {
    page: 1,
    q: '',
    provider_id: 0,
};
export const AllGames = () => {
    const dispatch: any = useDispatch();

    const [isMergeGamesDialogOpen, setIsMergeGamesDialogOpen] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<IReportGame | undefined>(undefined);
    const [selectedProviderId, setSelectedProviderId] = useState<any>(null);
    const { data, isInProgress, totalPages } = useSelector(allGamesDataSelector);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [params, setParams] = useState<IFetchAllGamesApiRequestBody>(defaultParams);

    const handleSelect = (providerId: any) => {
        setSelectedProviderId(providerId);
        setSelectedPage(1);
        setParams({ page: 1, q: searchTerm, provider_id: providerId });
    };
    const changePage = (page: number) => {
        setSelectedPage(page);
        setParams({ page: page, q: searchTerm, provider_id: selectedProviderId });
    };

    const search = (query: string) => {
        setSearchTerm(query);
        setSelectedPage(1);
        setParams({ page: 1, q: query, provider_id: selectedProviderId });
    };

    useEffect(() => {
        dispatch(fetchAllGames(params));
    }, [params]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <BreadCrumb title={'All Games'} />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Search search={search} placeholder="Search By Game Name..." />
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <DropDown
                                                            handleSelect={handleSelect}
                                                            isInProgress={isInProgress}
                                                            loadSuggestions={() => loadProviderSuggestions()}
                                                            placeholder="Filter By Provider..."
                                                            hideImage={true}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">Games</h4>
                                            </CardHeader>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap table-striped-columns mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col">ID</th>
                                                                    <th scope="col" colSpan={2}>
                                                                        Name
                                                                    </th>
                                                                    <th scope="col">Provider</th>
                                                                    <th scope="col">Identify only by image</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((game: any) => (
                                                                    <tr key={`game-${game.id}`}>
                                                                        <td>{game.id}</td>
                                                                        <td>{game?.name}</td>
                                                                        <td>
                                                                            <img
                                                                                className="img-thumbnail"
                                                                                width={200}
                                                                                src={game?.image}
                                                                            />
                                                                        </td>
                                                                        <td>{game?.provider?.name}</td>
                                                                        <td>
                                                                            <Switch
                                                                                defaultValue={game.identify_by_image}
                                                                                onChange={(value) => {
                                                                                    dispatch(
                                                                                        toggleIdentifyByImage({
                                                                                            id: game.id,
                                                                                            value,
                                                                                        })
                                                                                    );
                                                                                }}
                                                                                id={`toogle-identify-by-image-${game.id}`}
                                                                                label="Identify only by image"
                                                                            />
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setSelectedGame(game);
                                                                                    setIsMergeGamesDialogOpen(true);
                                                                                }}
                                                                                className="c-btn mt-2"
                                                                                color="primary"
                                                                            >
                                                                                Merge
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination currentPage={selectedPage} totalPages={totalPages} changePage={changePage} />
                </Container>
                <MergeGamesDialog
                    isOpen={isMergeGamesDialogOpen}
                    onClose={(refresh = false) => {
                        setIsMergeGamesDialogOpen(false);
                        setSelectedGame(undefined);

                        if (refresh) {
                            dispatch(fetchAllGames(params));
                        }
                    }}
                    childGame={selectedGame}
                />
                <ToastContainer />
            </div>
        </>
    );
};
