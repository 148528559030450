import { BaseApiService } from './BaseApiService';
import { IMergeProvidersApiRequestBody } from '../../slices/types';
import { IMergeProvidersResponse } from './IProviderApiService';

export class ProviderApiService {
    static MERGE_PROVIDERS_URL = '/providers/merge';

    static mergeProviders(params: IMergeProvidersApiRequestBody) {
        return BaseApiService.create<IMergeProvidersResponse>(ProviderApiService.MERGE_PROVIDERS_URL, params);
    }
}
