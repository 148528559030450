import { createSelector } from 'reselect';

export const accountStateSelector = (state: any) => state.Account;

export const userSelector = createSelector(accountStateSelector, (account) => {
    return {
        user: account.user,
    };
});

export const userRelatedDataSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData;
});

export const userRelatedFirstOperatorSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData.operators.length > 0 ? account.relatedData.operators[0] : null;
});

export const userRelatedFirstGameSelector = createSelector(accountStateSelector, (account) => {
    return account.relatedData.games.length > 0 ? account.relatedData.games[0] : null;
});

export const userRelatedDataCountsSelector = createSelector(
    accountStateSelector,
    (
        account
    ): {
        games: number;
        operators: number;
    } => {
        return {
            games: account.relatedData.games.length,
            operators: account.relatedData.operators.length,
        };
    }
);
