export const MoreItemsIcon = () => {
    return (
        <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.22058 6.00408C5.82718 6.41292 5.17282 6.41292 4.77942 6.00408L0.634261 1.69631C0.0229395 1.061 0.473177 0.00292997 1.35484 0.00293001L9.64516 0.00293046C10.5268 0.00293051 10.9771 1.061 10.3657 1.69631L6.22058 6.00408Z"
                fill="black"
            />
        </svg>
    );
};
