import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../Components/Hooks/UserHooks';
import { DashboardIcon } from './SVG/Menu/DashboardIcon';
import { AnalyticsIcon } from './SVG/Menu/AnalyticsIcon';
import { OperatorsIcon } from './SVG/Menu/OperatorsIcon';
import { GamesIcon } from './SVG/Menu/GamesIcon';

const Navdata = () => {
    const history = useNavigate();
    const { user } = useProfile();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isAnalytics, setIsAnalytics] = useState<boolean>(false);
    const [isApps, setIsApps] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isPages, setIsPages] = useState<boolean>(false);
    const [isBaseUi, setIsBaseUi] = useState<boolean>(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState<boolean>(false);
    const [isForms, setIsForms] = useState<boolean>(false);
    const [isTables, setIsTables] = useState<boolean>(false);
    const [isCharts, setIsCharts] = useState<boolean>(false);
    const [isIcons, setIsIcons] = useState<boolean>(false);
    const [isMaps, setIsMaps] = useState<boolean>(false);
    const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute('sub-items')) {
            const ul: any = document.getElementById('two-column-menu');
            const iconItems: any = ul.querySelectorAll('.nav-icon.active');
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove('active');
                var id = item.getAttribute('sub-items');
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove('show');
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (isCurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (isCurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (isCurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (isCurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (isCurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (isCurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (isCurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (isCurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (isCurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (isCurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (isCurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (isCurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (isCurrentState === 'Widgets') {
            history('/widgets');
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        isCurrentState,
        isDashboard,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
    ]);
    const menuItems: any = [];
    let otherMenuItems: any = [];
    if (user?.isAdmin) {
        otherMenuItems = [
            {
                id: 'all-games',
                label: 'Games',
                icon: <GamesIcon />,
                link: '/games/all',
                stateVariables: isCurrentState === 'AllGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllGames');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'not-found-games',
                label: 'Not Found Games',
                icon: <GamesIcon />,
                link: '/games/not-found',
                stateVariables: isCurrentState === 'NotFoundGames',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundGames');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-providers',
                label: 'Providers',
                icon: <OperatorsIcon />,
                link: '/providers/all',
                stateVariables: isCurrentState === 'AllProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllProviders');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'not-found-providers',
                label: 'Not Found Providers',
                icon: <OperatorsIcon />,
                link: '/providers/not-found',
                stateVariables: isCurrentState === 'NotFoundProviders',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('NotFoundProviders');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-operators',
                label: 'Operators',
                icon: <OperatorsIcon />,
                link: '/operators/all',
                stateVariables: isCurrentState === 'AllOperators',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllOperators');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'all-jobs',
                label: 'Jobs',
                icon: <DashboardIcon />,
                link: '/jobs/all',
                stateVariables: isCurrentState === 'AllJobs',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('AllJobs');
                    updateIconSidebar(e);
                },
            },
        ];
    } else {
        otherMenuItems = [
            {
                id: 'dashboard',
                label: 'Dashboards',
                icon: <DashboardIcon />,
                link: '/dashboard',
                stateVariables: isDashboard,
                click: function (e: any) {
                    e.preventDefault();
                    setIsDashboard(!isDashboard);
                    setIsCurrentState('Dashboard');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'analytics',
                label: 'Analytics',
                icon: <AnalyticsIcon />,
                link: '/#',
                stateVariables: isAnalytics,
                click: function (e: any) {
                    e.preventDefault();
                    setIsAnalytics(!isAnalytics);
                    setIsCurrentState('Analytics');
                    updateIconSidebar(e);
                },
                subItems: [
                    {
                        id: 'reports-portfolio',
                        label: 'Portfolio Overview',
                        icon: 'ri-focus-3-line',
                        link: '/reports/portfolio',
                        stateVariables: isCurrentState === 'PortfolioOverview',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('PortfolioOverview');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-games-on-operator',
                        label: 'Games Positioning',
                        icon: 'ri-focus-3-line',
                        link: '/reports/games-on-operator',
                        stateVariables: isCurrentState === 'GamesOnOperator',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('GamesOnOperator');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-games-on-sections',
                        label: 'Games On Sections',
                        icon: 'ri-focus-3-line',
                        link: '/reports/games-on-sections',
                        stateVariables: isCurrentState === 'GamesOnSections',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('GamesOnSections');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-position-updates',
                        label: 'Position Updates',
                        icon: 'ri-focus-3-line',
                        link: '/reports/position-updates',
                        stateVariables: isCurrentState === 'PositionUpdates',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('PositionUpdates');
                            updateIconSidebar(e);
                        },
                    },
                    {
                        id: 'reports-scores',
                        label: 'Positioning Scores',
                        icon: 'ri-focus-3-line',
                        link: '/reports/scores',
                        stateVariables: isCurrentState === 'Scores',
                        click: function (e: any) {
                            e.preventDefault();
                            setIsCurrentState('Scores');
                            updateIconSidebar(e);
                        },
                    },
                ],
            },
            {
                id: 'operators',
                label: 'Operators',
                icon: <OperatorsIcon />,
                link: '/configs/operators',
                stateVariables: isCurrentState === 'Operators',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('Operators');
                    updateIconSidebar(e);
                },
            },
            {
                id: 'games',
                label: 'Games',
                icon: <GamesIcon />,
                link: '/configs/games',
                stateVariables: isCurrentState === 'Games',
                click: function (e: any) {
                    e.preventDefault();
                    setIsCurrentState('Games');
                    updateIconSidebar(e);
                },
            },
        ];
    }

    for (const menuItem of otherMenuItems) {
        menuItems.push(menuItem);
    }
    if (user?.isAdmin) {
    } else {
    }
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;