import { UpSmallIcon } from '../../../Layouts/SVG/InfoCard/UpSmallIcon';
import { DownSmallIcon } from '../../../Layouts/SVG/InfoCard/DownSmallIcon';

export const GamePositionChange = ({
    position,
    oldPosition,
    showIcon = false,
}: {
    position: number;
    oldPosition: number;
    showIcon?: boolean;
}) => {
    const change = oldPosition - position;

    return (
        <div className={`game-position-change ${change < 0 && 'danger'}`}>
            {showIcon && (change > 0 ? <UpSmallIcon /> : <DownSmallIcon />)}
            {change > 0 && '+'}
            {change}
        </div>
    );
};
