export const DownIcon = () => {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.16159 10.5C5.53422 11.1667 6.46578 11.1667 6.83841 10.5L11.8689 1.5C12.2415 0.833333 11.7757 0 11.0304 0H0.969553C0.224302 0 -0.241479 0.833333 0.131147 1.5L5.16159 10.5Z"
                fill="#FF3E13"
            />
        </svg>
    );
};
