import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Pagination } from 'Components/Common/Pagination';
import { Search } from 'Components/Common/Search';
import { IFetchSelectedGamesApiRequestBody } from 'slices/types';
import { CreateNewGameDialog } from './GameDialog';
import { selectedGamesDataSelector } from 'selectors/SelectedGamesSelectors';
import { DeleteGameDialog } from './DeleteGameDialog';
import { useProfile } from 'Components/Hooks/UserHooks';
import { fetchSelectedGames } from 'slices/client-games/thunk';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { PlusIcon } from '../../../Layouts/SVG/InfoCard/PlusIcon';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { Label } from '../../../Components/Common/Label';

export const Games = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const [isCreateNewGameDialogOpen, setIsCreateNewGameDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isFromedit, setIsFromEdit] = useState<boolean>(false);
    const [selectedGame, setSelectedGame] = useState<any>(null);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedProviderId, setSelectedProviderId] = useState<any>(user?.isAdmin ? null : user?.providerId);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { data, isInProgress, totalPages } = useSelector(selectedGamesDataSelector);
    const [params, setParams] = useState<IFetchSelectedGamesApiRequestBody>({
        page: 1,
        q: '',
        provider_id: 0,
        page_size: 10,
    });

    const handleSelectProvider = (providerId: any) => {
        setSelectedProviderId(providerId);
        setSelectedPage(1);
        setParams({ page: 1, q: searchTerm, provider_id: providerId });
    };

    const search = (query: string) => {
        setSearchTerm(query);
        setSelectedPage(1);
        setParams({ ...params, page: 1, q: query });
    };

    const openEditDialog = (game: any): void => {
        setIsFromEdit(true);
        setIsCreateNewGameDialogOpen(true);
        setSelectedGame(game);
    };
    const openDeleteDialog = (game: any): void => {
        setDeleteDialogOpen(true);
        setSelectedGame(game);
    };
    const loadData = () => {
        dispatch(fetchSelectedGames(params));
    };

    useEffect(() => {
        loadData();
    }, [params]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle
                                    title={'Games'}
                                    buttons={[
                                        <Button
                                            onClick={() => setIsCreateNewGameDialogOpen(true)}
                                            color="primary"
                                            className={`c-btn ${selectedProviderId ? '' : 'disabled'}`}
                                        >
                                            <PlusIcon /> Add new game
                                        </Button>,
                                    ]}
                                />
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} sm={3}>
                                                        <Search search={search} placeholder="Search By Game Name..." />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {
                                                    <div className="table-responsive">
                                                        <table className="table table-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <td>Name</td>
                                                                    <td>Type</td>
                                                                    <td className="text-center">Status</td>
                                                                    <td className="text-end">Actions</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.map((game: any, index: number) => (
                                                                    <tr key={`game-${game.id}`}>
                                                                        <td>
                                                                            <GameRowItem game={game} hideType={true} />
                                                                        </td>
                                                                        <td>{game?.type}</td>
                                                                        <td className="text-center">
                                                                            {game.status === 'completed' && (
                                                                                <Label color="success">Completed</Label>
                                                                            )}
                                                                            {game.status === 'pending' && (
                                                                                <Label color="gray">In Progress</Label>
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openEditDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Edit
                                                                                </Button>{' '}
                                                                            </>
                                                                            <>
                                                                                {' '}
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        openDeleteDialog(game);
                                                                                    }}
                                                                                    color="primary"
                                                                                    size="sm"
                                                                                >
                                                                                    Delete
                                                                                </Button>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={totalPages}
                        changePage={(page) => {
                            setSelectedPage(page);
                            setParams({ ...params, page });
                        }}
                    />
                </Container>
            </div>
            <CreateNewGameDialog
                isOpen={isCreateNewGameDialogOpen}
                game={selectedGame}
                providerId={selectedProviderId}
                isEdit={isFromedit}
                onClose={(refresh = false) => {
                    setIsCreateNewGameDialogOpen(false);
                    setSelectedGame(null);
                    setIsFromEdit(false);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <DeleteGameDialog
                isOpen={isDeleteDialogOpen}
                game={selectedGame}
                onClose={(refresh = false) => {
                    setDeleteDialogOpen(false);
                    setSelectedGame(null);
                    if (refresh) {
                        loadData();
                    }
                }}
            />
            <ToastContainer />
        </>
    );
};
