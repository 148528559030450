export const OperatorsIcon = () => {
    return (
        <svg width="22" height="14" viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg" className="icon">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.98883 3C9.98883 4.66 8.65899 6 6.99918 6C5.33938 6 3.99953 4.66 3.99953 3C3.99953 1.34 5.33938 0 6.99918 0C8.65899 0 9.98883 1.34 9.98883 3ZM17.9879 3C17.9879 4.66 16.6581 6 14.9982 6C13.3384 6 11.9986 4.66 11.9986 3C11.9986 1.34 13.3384 0 14.9982 0C16.6581 0 17.9879 1.34 17.9879 3ZM6.99918 8C4.66946 8 0 9.17 0 11.5V13C0 13.55 0.449947 14 0.999883 14H12.9985C13.5484 14 13.9984 13.55 13.9984 13V11.5C13.9984 9.17 9.32891 8 6.99918 8ZM14.0284 8.05C14.3783 8.02 14.7083 8 14.9983 8C17.328 8 21.9974 9.17 21.9974 11.5V13C21.9974 13.55 21.5475 14 20.9975 14H15.8182C15.9281 13.69 15.9981 13.35 15.9981 13V11.5C15.9981 10.03 15.2082 8.92 14.0684 8.09C14.0653 8.08697 14.0623 8.08303 14.059 8.07872C14.0514 8.06881 14.0423 8.05697 14.0284 8.05Z"
            />
        </svg>
    );
};
