import {
    Badge,
    Button,
    Col,
    Form,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash-es';
import { searchGamesApi } from '../../../helpers/backend_helper';
import { useDispatch, useSelector } from 'react-redux';
import { createAndMatchGame, matchGames } from '../../../slices/games/thunk';
import { matchGamesDataSelector } from '../../../selectors/gamesSelectors';
import { toast } from 'react-toastify';
import { resetGamesReducerStatus } from '../../../slices/games/reducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const _loadSuggestions = (provider_id: number) => (q: string, callback: (response: any) => {}) => {
    const params: { q: string; provider_id?: number } = {
        q,
    };

    if (provider_id) {
        params.provider_id = provider_id;
    }

    searchGamesApi(params).then((response: any) => {
        return callback(
            response.data.map((game: { name: string; image: string; id: number; provider: { name: string } }) => {
                return {
                    image: game.image,
                    provider_name: game.provider.name,
                    label: game.name,
                    value: game.id,
                };
            })
        );
    });
};

export const MatchGamesDialog = ({
    isOpen,
    onClose,
    game,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    game?: any;
}) => {
    const dispatch: any = useDispatch();
    const [selectedGameId, setSelectedGameId] = useState<number>(0);
    const [checked, setChecked] = useState(true);
    const [useImageAsTheMain, setUseImageAsTheMain] = useState<boolean>(false);

    const loadSuggestions = debounce(_loadSuggestions(checked ? game?.provider_id : null), 300);

    const onMatchGames = (): void => {
        dispatch(matchGames({ game_id: selectedGameId, nfg_id: game.nfg_id, use_image: useImageAsTheMain }));
    };
    const onCreateNewGame = (name: string): void => {
        dispatch(createAndMatchGame({ nfg_id: game.nfg_id, name }));
    };
    const { message, success, isInProgress, isCreateAndMatchGameInProgress } = useSelector(matchGamesDataSelector);

    const handleClose = (refresh?: boolean) => {
        onClose(refresh);
        setChecked(true);
    };

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: game?.nfg_name,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter game name'),
        }),
        onSubmit: ({ name }) => {
            onCreateNewGame(name);
        },
    });

    useEffect(() => {
        if (!isInProgress && !isCreateAndMatchGameInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus());
                    },
                });

                handleClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetGamesReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress, isCreateAndMatchGameInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                handleClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
            className="modal-lg"
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && handleClose();
                }}
            >
                Match The Game
            </ModalHeader>
            <ModalBody className="text-center">
                <div className="mt-4">
                    <dl className="row mb-0">
                        <dt className="col-sm-3">Game Name</dt>
                        <dd className="col-sm-9">{game?.nfg_name}</dd>

                        <dt className="col-sm-3">Provider</dt>
                        <dd className="col-sm-9">
                            {game?.provider_name}{' '}
                            {!game?.provider_id && (
                                <>
                                    {' '}
                                    - <Badge color="danger">Not Found</Badge>
                                </>
                            )}
                        </dd>

                        <dt className="col-sm-3">Operator</dt>
                        <dd className="col-sm-9">{game?.operator_name}</dd>

                        <dt className="col-sm-3">Image</dt>
                        <dd className="col-sm-9">
                            <img className="img-thumbnail" width={200} src={game?.nfg_image} />
                        </dd>
                    </dl>
                </div>
                <div className="form-check form-switch form-switch-secondary">
                    <Input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                    <Label className="form-check-label" for="flexSwitchCheckChecked">
                        Search only in {game?.provider_name}'s games
                    </Label>
                </div>
                <div className="mt-4">
                    <AsyncSelect
                        isDisabled={isInProgress}
                        // @ts-ignore
                        loadOptions={loadSuggestions}
                        defaultOptions={[]}
                        formatOptionLabel={(option: {
                            label: string;
                            image?: string;
                            value: number;
                            provider_name: string;
                        }) => (
                            <div className="hstack gap-3" key={`option-${option.value}`}>
                                <div className="border p-1 px-2">
                                    <img className="img-thumbnail" width={200} src={option.image} />
                                </div>
                                <div className="p-1 px-2">
                                    {option.label} - by {option.provider_name}
                                </div>
                            </div>
                        )}
                        onChange={(res) => {
                            setSelectedGameId(res?.value || 0);
                        }}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Row className="full-width">
                    <Col lg={12}>
                        <Row>
                            <Col lg={5} className="d-grid p-0">
                                <Button
                                    className="c-btn"
                                    color="light"
                                    onClick={() => handleClose()}
                                    disabled={isInProgress || isCreateAndMatchGameInProgress}
                                >
                                    Close
                                </Button>
                            </Col>
                            <Col lg={2} className="" />
                            <Col lg={5} className="d-grid p-0">
                                <Button
                                    color="primary"
                                    onClick={onMatchGames}
                                    disabled={isInProgress || !selectedGameId || isCreateAndMatchGameInProgress}
                                >
                                    {isInProgress ? (
                                        <span className="d-flex align-items-center">
                                            <Spinner className="top-25 start-50" animation="border" color="light" />
                                            <span className="flex-grow-1 ms-2">Loading...</span>
                                        </span>
                                    ) : (
                                        'Match Selected Games'
                                    )}
                                </Button>
                                <div className="form-check form-switch form-switch-secondary">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked2"
                                        checked={useImageAsTheMain}
                                        onChange={(e) => setUseImageAsTheMain(e.target.checked)}
                                    />
                                    <Label className="form-check-label" for="flexSwitchCheckChecked2">
                                        Use this image as the main
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {game?.provider_id && (
                        <>
                            <Col lg={12} className="button-disclaimer-or display-flex justify-content-center">
                                OR
                            </Col>

                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Col lg={12}>
                                    <Row>
                                        <Col xs={12} md={5} className="d-block p-0">
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter name"
                                                name="name"
                                                value={validation.values.name || ''}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                        </Col>
                                        <Col xs={12} md={2} className="" />
                                        <Col xs={12} md={5} className="p-0">
                                            <Button
                                                color="primary"
                                                className="w-100"
                                                type="submit"
                                                disabled={isInProgress || isCreateAndMatchGameInProgress}
                                            >
                                                {isCreateAndMatchGameInProgress ? (
                                                    <span className="d-flex align-items-center">
                                                        <Spinner
                                                            className="top-25 start-50"
                                                            animation="border"
                                                            color="light"
                                                        />
                                                        <span className="flex-grow-1 ms-2">Creating...</span>
                                                    </span>
                                                ) : (
                                                    'Create new Game'
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Form>
                        </>
                    )}
                </Row>
            </ModalFooter>
        </Modal>
    );
};
