import React from 'react';
import { getComparisonColorFromSmallToBig } from '../../../utils/GameUtils'

interface RatioDisplayProps {
    current: number;
    total: number;
    type?: 'warning' | 'success' | 'danger';
}

export const RatioDisplay = ({ current, total, type }: RatioDisplayProps) => {
    return (
        <span className="ratio-display">
            <span className={type || getComparisonColorFromSmallToBig(current, total, [0.2, 0.8])}>{current}</span>/
            {total}
        </span>
    );
};
