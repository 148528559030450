import { SelectSelectedGame } from '../../Selects/SelectSelectedGame';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userRelatedDataSelector, userRelatedFirstGameSelector } from '../../../selectors/accountSelectors';
import { IUserRelatedGame } from '../../../Services/Api/Account/IAccountApiService';

interface GameMainFilterProps {
    isInProgress: boolean;
    onChange: (id: number) => void;
}

export const GameMainFilter = ({ isInProgress, onChange }: GameMainFilterProps) => {
    const { games } = useSelector(userRelatedDataSelector);
    const userRelatedGame = useSelector(userRelatedFirstGameSelector);
    const [selectedGameId, setSelectedGameId] = useState(userRelatedGame?.id);

    const selectedGame = games?.find((o: IUserRelatedGame) => o.id === selectedGameId);
    const selectedGameName = selectedGame ? selectedGame.name : userRelatedGame?.name;

    useEffect(() => {
        if (userRelatedGame) {
            setSelectedGameId(userRelatedGame.id);
            onChange(userRelatedGame.id);
        }
    }, [userRelatedGame]);

    return (
        <div className="main-filter">
            <div className="title">{selectedGameName}</div>
            <div className="filter">
                Select Game
                <div className="filter-select">
                    {selectedGameId && (
                        <SelectSelectedGame
                            defaultValue={{
                                value: selectedGameId,
                                label: selectedGameName,
                            }}
                            setValue={(id: number) => {
                                setSelectedGameId(id);
                                onChange(id);
                            }}
                            isInProgress={isInProgress}
                            isClearable={false}
                            placeholder="* Filter By Game..."
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
