import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface PageTitleProps {
    title: string;
    buttons?: React.ReactNode[];
}

export const PageTitle = ({ title, buttons = [] }: PageTitleProps) => {
    return (
        <Row>
            <Col xs={12}>
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">{title}</h4>
                    {buttons}
                </div>
            </Col>
        </Row>
    );
};
