export const GameIcon = ({ color }: { color?: string }) => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"
             className={`icon ${color}`}>
            <circle cx="22" cy="22" r="22" />
            <path d="M34 10H10V34H34V10Z" fill="white" fillOpacity="0.01" />
            <path d="M32 24H24V32H32V24Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 24C23 23.4477 23.4477 23 24 23H32C32.5523 23 33 23.4477 33 24V32C33 32.5523 32.5523 33 32 33H24C23.4477 33 23 32.5523 23 32V24ZM25 25V31H31V25H25Z"
                fill="white"
            />
            <path d="M16.5 12L21 20H12L16.5 12Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 11C16.8613 11 17.1945 11.1949 17.3716 11.5097L21.8716 19.5097C22.0457 19.8194 22.0426 20.1982 21.8632 20.5048C21.6839 20.8115 21.3553 21 21 21H12C11.6447 21 11.3161 20.8115 11.1368 20.5048C10.9574 20.1982 10.9543 19.8194 11.1284 19.5097L15.6284 11.5097C15.8055 11.1949 16.1387 11 16.5 11ZM13.7098 19H19.2902L16.5 14.0397L13.7098 19Z"
                fill="white"
            />
            <path
                d="M28 20C30.2092 20 32 18.2092 32 16C32 13.7909 30.2092 12 28 12C25.7909 12 24 13.7909 24 16C24 18.2092 25.7909 20 28 20Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 16C23 13.2386 25.2386 11 28 11C30.7614 11 33 13.2386 33 16C33 18.7614 30.7614 21 28 21C25.2386 21 23 18.7614 23 16ZM28 13C26.3431 13 25 14.3431 25 16C25 17.6569 26.3431 19 28 19C29.6569 19 31 17.6569 31 16C31 14.3431 29.6569 13 28 13Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.2929 23.2929C11.6834 22.9024 12.3166 22.9024 12.7071 23.2929L20.7071 31.2929C21.0976 31.6834 21.0976 32.3166 20.7071 32.7071C20.3166 33.0976 19.6834 33.0976 19.2929 32.7071L11.2929 24.7071C10.9024 24.3166 10.9024 23.6834 11.2929 23.2929Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7071 23.2929C21.0976 23.6834 21.0976 24.3166 20.7071 24.7071L12.7071 32.7071C12.3166 33.0976 11.6834 33.0976 11.2929 32.7071C10.9024 32.3166 10.9024 31.6834 11.2929 31.2929L19.2929 23.2929C19.6834 22.9024 20.3166 22.9024 20.7071 23.2929Z"
                fill="white"
            />
        </svg>
    )
}
