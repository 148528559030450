import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mergeProviders } from '../../../slices/providers/thunk';
import { mergeProvidersDataSelector } from '../../../selectors/providersSelectors';
import { toast } from 'react-toastify';
import { resetProvidersReducerStatus } from '../../../slices/providers/reducer';
import { IProvider } from '../../../slices/types';
import { DropDown } from '../../../Components/Common/DropDown';
import { loadProviderSuggestions } from '../../../helpers/loadProviderSuggestions';

export const MergeProvidersDialog = ({
    isOpen,
    onClose,
    childProvider,
}: {
    isOpen: boolean;
    onClose: (refresh?: boolean) => void;
    childProvider?: IProvider;
}) => {
    const dispatch: any = useDispatch();
    const [parentProvider, setParentProvider] = useState<IProvider | undefined>(undefined);

    const onMergeProviders = (): void => {
        if (parentProvider && childProvider) {
            dispatch(mergeProviders({ child_id: childProvider?.id, parent_id: parentProvider?.id }));
        }
    };

    const { message, success, isInProgress } = useSelector(mergeProvidersDataSelector);

    useEffect(() => {
        if (!isInProgress && success !== null) {
            if (success === true) {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-success text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetProvidersReducerStatus());
                    },
                });

                onClose(true);
            } else {
                toast(message, {
                    position: 'top-right',
                    hideProgressBar: false,
                    className: 'bg-danger text-white',
                    autoClose: 4000,
                    onClose: () => {
                        dispatch(resetProvidersReducerStatus());
                    },
                });
            }
        }
    }, [isInProgress]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => {
                onClose();
            }}
            backdrop={'static'}
            id="staticBackdrop"
            centered
        >
            <ModalHeader
                className="modal-title"
                id="myModalLabel"
                toggle={() => {
                    !isInProgress && onClose();
                }}
            >
                Merge Providers
            </ModalHeader>
            <ModalBody className="text-center">
                {parentProvider ? (
                    <Alert color="primary">
                        <strong>{childProvider?.name} Provider</strong> will be removed and all its games will be moved
                        under <strong>{parentProvider.name}</strong> with its{' '}
                        <strong>{childProvider?.game_count} games</strong>
                    </Alert>
                ) : (
                    <Alert color="primary">
                        Please select a Provider under which <strong>{childProvider?.name} Provider</strong> will be
                        moved with its <strong>{childProvider?.game_count} games</strong>
                    </Alert>
                )}
                <div className="mt-4">
                    <DropDown
                        placeholder={'Search for a parent Provider'}
                        handleSelect={(_, selectedOption) => {
                            setParentProvider(
                                selectedOption
                                    ? ({
                                          id: selectedOption.value,
                                          name: selectedOption.label,
                                      } as IProvider)
                                    : undefined
                            );
                        }}
                        isInProgress={isInProgress}
                        loadSuggestions={() => loadProviderSuggestions()}
                        imageSize={100}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => onClose()} disabled={isInProgress}>
                    Close
                </Button>
                <Button color="primary" onClick={onMergeProviders} disabled={isInProgress || !parentProvider}>
                    {isInProgress ? (
                        <span className="d-flex align-items-center">
                            <Spinner className="top-25 start-50" animation="border" color="light" />
                            <span className="flex-grow-1 ms-2">Merging...</span>
                        </span>
                    ) : (
                        'Merge Providers'
                    )}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
