import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGamesOnOperator } from '../../../slices/reports/thunk';
import { gamesOnOperatorDataSelector } from '../../../selectors/reportsSelectors';
import { AllGamesDialog } from '../Portfolio/AllGamesDialog';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { resetGamesOnSectionsAction } from '../../../slices/reports/reducer';
import { IReportGamesOnOperatorSectionRow } from '../../../Services/Api/IReportApiService';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { userRelatedDataCountsSelector } from '../../../selectors/accountSelectors';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { getUniqueGamesCountFromSection, splitSectionName } from '../../../utils/SectionUtils';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { NoDataTableRow } from '../../../Components/Common/Table/NoDataTableRow';
import { OperatorMainFilter } from '../../../Components/Common/Filter/OperatorMainFilter';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';

export const GamesOnOperator = () => {
    const dispatch: any = useDispatch();
    const { user } = useProfile();
    const relatedDataCounts = useSelector(userRelatedDataCountsSelector);
    const { sections, isInProgress } = useSelector(gamesOnOperatorDataSelector);

    const [selectedSection, setSelectedSection] = useState<IReportGamesOnOperatorSectionRow | null>(null);
    const [selectedProviderId] = useState(user.providerId);
    const [selectedGameId, setSelectedGameId] = useState(0);
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
    const [selectedOperatorId, setSelectedOperatorId] = useState(0);

    useEffect(() => {
        if (selectedOperatorId) {
            dispatch(fetchGamesOnOperator(selectedProviderId, selectedGameId, selectedOperatorId));
        } else {
            dispatch(resetGamesOnSectionsAction());
        }
    }, [selectedProviderId, selectedGameId, selectedOperatorId, dispatch]);

    const totalFoundGames = getUniqueGamesCountFromSection(sections);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Game Positioning'} />
                                <OperatorMainFilter isInProgress={isInProgress} onChange={setSelectedOperatorId} />
                                <Row>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            title="Active Games"
                                            iconType="game"
                                            amount={totalFoundGames}
                                            subAmount={relatedDataCounts.games}
                                            amountColor="warning"
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={setSelectedGameId}
                                                            isInProgress={isInProgress}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {isInProgress ? (
                                                    <></>
                                                ) : (
                                                    <div className="table-with-manu">
                                                        <div className="menu">
                                                            <div className="title">Operators</div>
                                                            <ul>
                                                                {sections.map(({ section, games }, index) => {
                                                                    return (
                                                                        <li
                                                                            key={`section-${section.id}`}
                                                                            onClick={() => {
                                                                                setSelectedSectionIndex(index);
                                                                            }}
                                                                            className={
                                                                                index === selectedSectionIndex
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            {splitSectionName(section.name).join('')}
                                                                            <RatioDisplay
                                                                                current={games.length}
                                                                                total={relatedDataCounts.games}
                                                                                type="warning"
                                                                            />
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-nowrap">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Game</td>
                                                                        <td>Current Position</td>
                                                                        <td>View</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {sections[selectedSectionIndex]?.games.length ===
                                                                    0 ? (
                                                                        <NoDataTableRow colSpan={3} />
                                                                    ) : (
                                                                        sections[selectedSectionIndex]?.games?.map(
                                                                            (game) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={`game-info-${selectedSectionIndex}-${game.id}`}
                                                                                    >
                                                                                        <td>
                                                                                            <GameRowItem game={game} />
                                                                                        </td>
                                                                                        <td className="position">
                                                                                            {game.position}
                                                                                        </td>
                                                                                        <td>
                                                                                            {game.position! < 200 && (
                                                                                                <a
                                                                                                    target="_blank"
                                                                                                    href={
                                                                                                        sections[
                                                                                                            selectedSectionIndex
                                                                                                        ].section
                                                                                                            .screenshot
                                                                                                    }
                                                                                                >
                                                                                                    Screenshot
                                                                                                </a>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AllGamesDialog
                isOpen={!!selectedSection}
                onClose={() => {
                    setSelectedSection(null);
                }}
                itemKey={`section-${selectedSection?.section.id}`}
                title={`All games on ${selectedSection?.section.name} (${selectedSection?.section.game_count}) section`}
                games={selectedSection?.games}
            />
        </React.Fragment>
    );
};
