import { APIClient } from '../../helpers/api_helper';

const api = new APIClient();

export class BaseApiService {
    static get<T>(url: string, params?: any): Promise<T> {
        return api.get<T>(url, params);
    }

    static create<T>(url: string, params?: any): Promise<T> {
        return api.create<T>(url, params);
    }

    static put<T>(url: string, params?: any): Promise<T> {
        return api.put<T>(url, params);
    }

    static delete<T>(url: string): Promise<T> {
        return api.delete<T>(url);
    }

    static fillParams(url: string, params: Record<string, string | number | boolean>): string {
        for (const key in params) {
            url = url.replace(`{${key}}`, `${params[key]}`);
        }

        return url;
    }
}
