import { IReportGamesOnOperatorSectionRow } from '../Services/Api/IReportApiService';

export function splitSectionName(name: string, count = 1): string[] {
    const chunks = name.split(' | ');

    return chunks.slice(chunks.length - count);
}

export function getUniqueGamesCountFromSection(sections: IReportGamesOnOperatorSectionRow[]): number {
    const uniqueGames = new Set<number>();

    sections.forEach(({ games }) => {
        games.forEach((game) => uniqueGames.add(game.id));
    });

    return uniqueGames.size;
}
