export const NoDataTableRow = ({ colSpan = 2 }: { colSpan: number }) => {
    return (
        <tr className="no-data-table-row">
            {/* @ts-ignore*/}
            <td colSpan={colSpan}>
                <div>
                    <i className="ri-alert-fill" />
                    <span>No Data</span>
                </div>
            </td>
        </tr>
    );
};
