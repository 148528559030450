import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPositionUpdates } from '../../../slices/reports/thunk';
import { gamesPositionUpdatesSelector } from '../../../selectors/reportsSelectors';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { SelectSelectedGame } from '../../../Components/Selects/SelectSelectedGame';
import { SelectSelectedOperator } from '../../../Components/Selects/SelectSelectedOperator';
import { PageTitle } from '../../../Components/Common/PageTitle';
import { InfoCard } from '../../../Components/Common/InfoCard';
import { getComparisonColor, getOperatorsCountWithPositiveChange } from '../../../utils/GameUtils';
import { ClearFilters } from '../../../Components/Common/ClearFilters';
import { SectionRowItem } from '../../../Components/Common/Table/SectionRowItem';
import { GameRowItem } from '../../../Components/Common/Table/GameRowItem';
import { RatioDisplay } from '../../../Components/Common/Table/RatioDisplay';
import { GamePositionChange } from '../../../Components/Common/Table/GamePositionChange';
import { resetPositionUpdatesAction } from '../../../slices/reports/reducer';

export const PositionUpdates = () => {
    const dispatch: any = useDispatch();
    const { operators, isInProgress } = useSelector(gamesPositionUpdatesSelector);
    const { user } = useProfile();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedProviderId, setSelectedProviderId] = useState(user.isAdmin ? 0 : user.providerId!);
    const [selectedGameIds, setSelectedGameIds] = useState<number[]>([]);
    const [selectedOperatorIds, setSelectedOperatorIds] = useState<number[]>([]);
    const [selectedOperatorIndex, setSelectedOperatorIndex] = useState<number>(0);

    useEffect(() => {
        dispatch(
            fetchPositionUpdates(
                selectedProviderId,
                selectedGameIds,
                selectedOperatorIds,
                moment(selectedDate).format('YYYY-MM-DD')
            )
        );
    }, [selectedProviderId, selectedGameIds, selectedOperatorIds, selectedDate]);

    useEffect(() => {
        return () => {
            dispatch(resetPositionUpdatesAction());
        };
    }, []);

    const { operatorPositiveChangeCount, operatorNegativeChangeCount } = getOperatorsCountWithPositiveChange(operators);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="h-100">
                                <PageTitle title={'Position Updates'} />
                                <Row>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            description="Operators where your games gained positions"
                                            iconType="operator"
                                            amount={operatorPositiveChangeCount}
                                            amountColor={operatorPositiveChangeCount > 0 ? 'success' : 'warning'}
                                            amountType={operatorPositiveChangeCount > 0 ? 'up' : ''}
                                        />
                                    </Col>
                                    <Col xl={3} md={6} sm={12}>
                                        <InfoCard
                                            description="Operators where your games lost positions"
                                            iconType="game"
                                            iconColor="warning"
                                            amount={operatorNegativeChangeCount}
                                            amountColor={operatorNegativeChangeCount > 0 ? 'danger' : 'success'}
                                            amountType={operatorNegativeChangeCount > 0 ? 'down' : ''}
                                        />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Flatpickr
                                            className="form-control"
                                            id="position-update-datepicker"
                                            placeholder="* Select a date"
                                            options={{
                                                altInput: true,
                                                altFormat: 'F j, Y',
                                                dateFormat: 'YYYY-MM-DD',
                                            }}
                                            onChange={(v) => {
                                                setSelectedDate(v[0]);
                                            }}
                                            value={selectedDate}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardTitle>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedGame
                                                            setValue={(id) => {
                                                                setSelectedGameIds(id as any as number[]);
                                                            }}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Games..."
                                                            isMulti={true}
                                                            value={selectedGameIds}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <SelectSelectedOperator
                                                            setValue={(id) => {
                                                                setSelectedOperatorIds(id as any as number[]);
                                                            }}
                                                            isInProgress={isInProgress}
                                                            placeholder="Filter By Operators..."
                                                            isMulti={true}
                                                            value={selectedOperatorIds}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <ClearFilters
                                                            methods={[
                                                                () => setSelectedOperatorIds([]),
                                                                () => setSelectedGameIds([]),
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardTitle>
                                            <CardBody>
                                                {!selectedProviderId || !selectedDate ? (
                                                    <Alert color="primary" className="mb-0">
                                                        Please select required filters
                                                    </Alert>
                                                ) : isInProgress ? (
                                                    <></>
                                                ) : operators.length === 0 ? (
                                                    <Alert color="primary" className="mb-0">
                                                        No data found for selected filters
                                                    </Alert>
                                                ) : (
                                                    <div className="table-with-manu">
                                                        <div className="menu">
                                                            <div className="title">Operators</div>
                                                            <ul>
                                                                {operators.map(({ operator, update }, index) => {
                                                                    return (
                                                                        <li
                                                                            key={`operator-${operator.id}`}
                                                                            onClick={() => {
                                                                                setSelectedOperatorIndex(index);
                                                                            }}
                                                                            className={
                                                                                index === selectedOperatorIndex
                                                                                    ? 'active'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            {operator.name}
                                                                            <span className="position-change">
                                                                                {!!update.positive_count && (
                                                                                    <GamePositionChange
                                                                                        position={
                                                                                            -1 * update.positive_count
                                                                                        }
                                                                                        oldPosition={0}
                                                                                    />
                                                                                )}
                                                                                {!!update.negative_count && (
                                                                                    <GamePositionChange
                                                                                        position={update.negative_count}
                                                                                        oldPosition={0}
                                                                                    />
                                                                                )}
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Game</td>
                                                                        <td>Page</td>
                                                                        <td>Position</td>
                                                                        <td>Change</td>
                                                                        <td>View</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {operators[selectedOperatorIndex]?.games.map(
                                                                        (game) => {
                                                                            return (
                                                                                <tr
                                                                                    key={`game-info-${game.change_id!}`}
                                                                                >
                                                                                    <td>
                                                                                        <GameRowItem game={game} />
                                                                                    </td>
                                                                                    <td>
                                                                                        <SectionRowItem
                                                                                            name={game.section!.name}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <RatioDisplay
                                                                                            current={game.position!}
                                                                                            total={
                                                                                                game.section!.game_count
                                                                                            }
                                                                                            type={
                                                                                                game.old_position! >
                                                                                                game.position!
                                                                                                    ? 'success'
                                                                                                    : 'danger'
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <GamePositionChange
                                                                                            showIcon={true}
                                                                                            position={game.position!}
                                                                                            oldPosition={
                                                                                                game.old_position!
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        {game.position! < 200 && (
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={
                                                                                                    game.section!
                                                                                                        .screenshot
                                                                                                }
                                                                                            >
                                                                                                Screenshot
                                                                                            </a>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
