import { IReportPositionUpdatesRow } from '../Services/Api/IReportApiService';
import { IDashboardPositionUpdatesRow } from '../Services/Api/IDashboardApiService';

export function getComparisonColor(
    current: number,
    total: number,
    percentages: number[] = [0.8, 0.2],
    classNames = ['success', 'warning']
): 'success' | 'warning' | 'danger' {
    if (percentages.length !== classNames.length) {
        throw new Error('percentages and classNames arrays must have the same length');
    }

    for (let i = 0; i < percentages.length; i++) {
        if (current > total * percentages[i]) {
            // @ts-ignore
            return classNames[i];
        }
    }

    return 'danger';
}

export function getComparisonColorFromSmallToBig(
    current: number,
    total: number,
    percentages: number[] = [0.2, 0.8],
    classNames: string[] = ['success', 'warning']
): string {
    if (percentages.length !== classNames.length) {
        throw new Error('percentages and classNames arrays must have the same length');
    }

    for (let i = 0; i < percentages.length; i++) {
        if (current < total * percentages[i]) {
            return classNames[i];
        }
    }

    return 'danger';
}

export function getOperatorsCountWithPositiveChange(operators: IReportPositionUpdatesRow[]): {
    operatorPositiveChangeCount: number;
    operatorNegativeChangeCount: number;
} {
    let result = {
        operatorPositiveChangeCount: 0,
        operatorNegativeChangeCount: 0,
    };

    operators.forEach(({ update }) => {
        result.operatorPositiveChangeCount += update.positive_count;
        result.operatorNegativeChangeCount += update.negative_count;
    });

    return result;
}

export function getOperatorsCountWithPositiveChangeForDashboard(updatesRows: IDashboardPositionUpdatesRow[]): {
    operatorPositiveChangeCount: number;
    operatorNegativeChangeCount: number;
} {
    let result = {
        operatorPositiveChangeCount: 0,
        operatorNegativeChangeCount: 0,
    };

    updatesRows.forEach(({ update }) => {
        result.operatorPositiveChangeCount += update.positive_count;
        result.operatorNegativeChangeCount += update.negative_count;
    });

    return result;
}
