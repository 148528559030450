import { IReportGame } from '../../../Services/Api/IReportApiService';

export const SectionRowItem = ({ name }: { name: string }) => {
    const sections = name.split(' | ');

    return (
        <div className="section-row-item">
            {sections.map((section, index) => {
                const lastItem = index === sections.length - 1;
                return (
                    <>
                        <div className={`label-badge ${lastItem ? '' : 'inactive'}`}>{section}</div>
                        {!lastItem && <i className="ri-arrow-right-line" />}
                    </>
                );
            })}
        </div>
    );
};
