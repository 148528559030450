import { BaseApiService } from './BaseApiService';
import { IDashboardDataForPositionUpdatesResponse } from './IDashboardApiService';

export class DashboardApiService {
    static FETCH_DASHBOARD_DATA_FOR_POSITION_UPDATES_URL = '/dashboard/position-updates';

    static getDashboardDataForPositionUpdates() {
        return BaseApiService.get<IDashboardDataForPositionUpdatesResponse>(
            DashboardApiService.FETCH_DASHBOARD_DATA_FOR_POSITION_UPDATES_URL
        );
    }
}
