import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash-es';

type IDropDownProps = {
    value?: number | any;
    handleSelect: (selectedOptionId: any, selectedOption: any) => void;
    isInProgress: boolean;
    loadSuggestions: (...args: any) => any;
    placeholder?: string;
    hideImage?: boolean;
    imageSize?: number;
    isMulti?: boolean;
    defaultOptions?: any[];
    defaultValue?: number | any;
    isClearable?: boolean;
};

export const DropDown = ({
    value,
    handleSelect,
    isInProgress,
    loadSuggestions,
    placeholder = 'Search...',
    hideImage = false,
    imageSize = 50,
    isMulti = false,
    defaultOptions = [],
    defaultValue = null,
    isClearable = true,
}: IDropDownProps) => {
    const _loadSuggestions = debounce(loadSuggestions(), 300);

    const [localValue, setLocalValue] = useState(isMulti ? [] : null);
    useEffect(() => {
        if (value === 0 || (Array.isArray(value) && value.length === 0)) {
            setLocalValue(null);
        }
    }, [value]);

    return (
        <AsyncSelect
            defaultValue={defaultValue}
            isDisabled={isInProgress}
            loadOptions={_loadSuggestions}
            placeholder={placeholder}
            defaultOptions={defaultOptions}
            formatOptionLabel={(option: { label: string; image?: string; value: number }) => (
                <div className="hstack gap-3" key={`option-${option.value}`}>
                    {!hideImage && <img className=" img-thumbnail" width={imageSize} src={option.image} />}
                    <div className=" p-1 px-2">{option.label}</div>
                </div>
            )}
            value={localValue || defaultValue}
            isMulti={isMulti}
            isClearable={isClearable}
            onChange={(res) => {
                if (isMulti) {
                    // @ts-ignore
                    handleSelect(res?.map((i: { value: any }) => i.value) || [], res);
                    // @ts-ignore
                    setLocalValue(res);
                } else {
                    // @ts-ignore
                    handleSelect(res?.value || 0, res);
                    // @ts-ignore
                    setLocalValue(res);
                }
            }}
        />
    );
};
