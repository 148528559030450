import { BaseApiService } from './BaseApiService';
import { IReportGamesOnOperatorResponse } from './IReportApiService';
import {
    IMergeGamesApiRequestBody,
    IMergeGamesResponse,
    IToggleIdentifyGameByImageApiRequestBody,
} from './IGameApiService';

export class GameApiService {
    static TOGGLE_IDENTIFY_GAME_BY_IMAGE_URL = '/games/{id}/identify-by-image';
    static MERGE_GAMES_URL = '/games/merge';

    static toggleIdentifyGameByImage(params: IToggleIdentifyGameByImageApiRequestBody) {
        return BaseApiService.put<IReportGamesOnOperatorResponse>(
            BaseApiService.fillParams(GameApiService.TOGGLE_IDENTIFY_GAME_BY_IMAGE_URL, { id: params.id }),
            {
                value: params.value ? 1 : 0,
            }
        );
    }

    static mergeGames(params: IMergeGamesApiRequestBody) {
        return BaseApiService.create<IMergeGamesResponse>(GameApiService.MERGE_GAMES_URL, params);
    }
}
