import React from 'react';
import Select from 'react-select';
import { OperatorScraperTypes } from '../../Services/Api/IOperatorApiService';

type SelectOperatorStatusProps = {
    setValue: (status: OperatorScraperTypes) => void;
    defaultValue?: OperatorScraperTypes;
    placeholder: string;
    allOption?: boolean;
};

const options = [
    { value: OperatorScraperTypes.PPTR, label: 'Puppeteer' },
    { value: OperatorScraperTypes.WDIO, label: 'WebDriver.io' },
];

export const SelectOperatorScraperType = ({
    setValue,
    placeholder = 'Filter By Status...',
    defaultValue,
    allOption = false,
}: SelectOperatorStatusProps) => {
    return (
        <Select
            isClearable={false}
            isSearchable={true}
            name="color"
            placeholder={placeholder}
            options={allOption ? [{ value: 'all', label: 'All' }, ...options] : options}
            onChange={(e: { value: OperatorScraperTypes; label: string }) => {
                setValue(e.value);
            }}
            value={defaultValue ? options.find((option) => option.value === defaultValue) : undefined}
        />
    );
};
